import React, { useState } from 'react'
import { Autocomplete, Box, Breadcrumbs, Button, Card, CardContent, Chip, Grid2, IconButton, TextField, Typography } from '@mui/material'
import Grid from "@mui/material/Grid2";
import { makeStyles } from "@mui/styles";
import { useFormik } from 'formik';
import * as Yup from "yup";
import DeleteIcon from "@mui/icons-material/Delete";
import { toast } from 'react-toastify';
import { useDispatch } from 'react-redux';
import { fetchCreateContentSlice } from '../../../redux/teamSlice/team.slice';
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import CustomSwitch from '../../../components/CustomSwitch/CustomSwitch';
import Upload from '../../../assets/Upload.svg'
import ImagePreviewModal from './ImagePreviewModal';
import WhatsAppDemoVideo from '../../../assets/DemoVideo.jpg'
import CustomBreadcrumb from '../../../components/CustomBreadcrumb/CustomBreadcrumb';


const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },

    textarea: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },

    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
    },
    label: {
        color: "#7C7C7C",
        fontWeight: 500,
        marginBottom: "2px",
        fontSize: "18px !important",
    },

    Typo: {
        color: "#455967",
        fontWeight: 500,
        marginBottom: "2px",
        fontSize: "16px !important",
    },

    fetchButton: {
        borderRadius: '10px',
        border: '1px solid #A9A9A9',
        color: '#FFFFFF !important',
        fontSize: '18px !important',
        background: 'linear-gradient(180deg, #13BECF 0%, #455869 100%)',
        padding: '8px 16px !important'
    }
}));

const contentTypeList = [
    {
        label: 'Public',
        value: 'PUBLIC',
    },
    {
        label: 'Private',
        value: 'Private',
    }
];

const CreateContent = () => {

    const classes = useStyles();

    const breadcrumb = [
        {
            title:'Home',
            href:'dashboard',
        },
        {
            title:'Content Library',
            href:'content-library',
        },
        {
            title:'Create New Content',
            href:'',
        }
    ];

    const dispatch = useDispatch();
    const [selectedFile, setSelectedFile] = useState(null);
    const [openImage, setOpenImage] = useState(false);

    const initialValues = {
        contentName: '',
        contentType: '',
        contentDesc: '',
        status: false,
    }

    const validationSchema = Yup.object().shape({
        contentName:  Yup.string().required("Content name is required"),
        contentType:  Yup.string().required("Content type is required"),
        contentDesc:  Yup.string().required("Content Description is required"),
    })

    const handleFileChange = async (event) => {
        const file = event.target.files[0]; // Get the first selected file

        if (file) {
            const allowedTypes = ["image/", "video/"];
            if (allowedTypes.some((type) => file.type.startsWith(type))) {

                const reader = new FileReader(); // Create a new FileReader instance for each file

                reader.onloadend = () => {

                    const base64Data = reader.result.split(",")[1];

                    // Remove the prefix (e.g., "data:image/jpeg;base64,")
                    const extension = file.name.split('.').pop();

                    setSelectedFile({
                        base64: base64Data,
                        fileType: file.type, // e.g., "image/jpeg" or "video/mp4"
                        fileExtension: extension, // e.g., "jpg" or "mp4"
                        fileSize: (file.size / 1024).toFixed(2), // Convert bytes to KB and round to 2 decimals
                    });

                };

                reader.onerror = (error) => {
                    toast.error("Error uploading file. Please try again.");
                };

                reader.readAsDataURL(file); // Read the file as a Base64 string
            } else {
                toast.error("Please upload a valid image or video file.");
                event.target.value = ""; // Reset the file input
            }
        }
    };

    const handleRemoveFile = () => {
        setSelectedFile(null);
    };

    const handleClose = () => {
        setOpenImage(false);
    }

    const handleSubmit = async (values) => {

        if (!selectedFile) {
            toast.error("Please upload a file before submitting.");
            return;
        }

        const payload = {
            contentType: values.contentType,
            name: values.contentName,
            description: values.contentDesc,
            status: values.status === true ? 'ACTIVE' : 'INACTIVE',
            content: selectedFile.base64,
            fileType: selectedFile.fileType,
            fileExtension: selectedFile.fileExtension
        }

        const result = await dispatch(fetchCreateContentSlice(payload)).unwrap();

        if (result) {

        } else {
            console.log("error")
        }
    }

    const formik = useFormik({
        initialValues: initialValues,
        validationSchema: validationSchema,
        onSubmit: handleSubmit
    })

    console.log("selectedFile", selectedFile);

    console.log("formik---", formik);

    return (
        <form onSubmit={formik.handleSubmit}>
            <Box>
                {/* <Breadcrumbs aria-label="breadcrumb">
                    <Typography
                        underline="hover"
                        sx={{ display: 'flex', alignItems: 'center' }}
                        color="inherit"
                        href="/dashboard"
                    >
                        Home
                    </Typography>
                    <Typography sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center' }}>
                        Content Library
                    </Typography>
                    <Typography
                        sx={{ color: "text.primary", display: "flex", alignItems: "center" }}
                    >
                        Create New Content
                    </Typography>
                </Breadcrumbs> */}

                <CustomBreadcrumb breadcrumb={breadcrumb}/>

                <Box sx={{ display: 'flex', gap: '24px', alignItems: 'center', marginTop: '24px' }}>

                    <Typography sx={{ fontSize: '22px', color: '#445A68', fontWeight: '600' }}>
                        New Content Details
                    </Typography>
                    <CustomSwitch
                        checked={formik.values.status}
                        onChange={(event) => formik.setFieldValue("status", event.target.checked)}
                    />
                </Box>

                <Card sx={{ mt: 5, p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}>
                    <Grid container spacing={3}>

                        <Grid size={{ md: 6 }}>
                            <Typography className={classes.label}>Content Name{" "}<span style={{ color: '#FF0000' }}>*</span></Typography>

                            <TextField
                                name="contentName"
                                placeholder='Enter Content Name'
                                value={formik.values.contentName}
                                onChange={formik.handleChange}
                                className={classes.textField}
                                error={formik.touched.contentName && Boolean(formik.errors.contentName)}
                                helperText={formik.touched.contentName && formik.errors.contentName}
                            />
                        </Grid>
                        <Grid size={{ md: 6 }}>
                            <Typography className={classes.label}>Content Type{" "}<span style={{ color: '#FF0000' }}>*</span></Typography>

                            <Autocomplete
                                // disablePortal
                                name="contentType"
                                options={contentTypeList}
                                getOptionLabel={(option) =>
                                    option?.label ? option.label : ""
                                }
                                onChange={(e, value) => {
                                    formik.setFieldValue("contentType", value ? value.value : "")

                                }}
                                onBlur={formik.handleBlur}
                                className={classes.selectField}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        placeholder='Select Template Category'
                                        error={
                                            formik.touched.contentType && Boolean(formik.errors.contentType)
                                        }
                                        helperText={
                                            formik.touched.contentType && formik.errors.contentType
                                        }
                                        className={classes.textField}
                                    />
                                )}
                            />
                        </Grid>

                        <Grid size={{ md: 12 }}>
                            <Typography className={classes.label}>Content Description{" "}<span style={{ color: '#FF0000' }}>*</span></Typography>

                            <TextField
                                name="contentDesc"
                                multiline
                                className={classes.textarea}
                                rows={4}
                                fullWidth
                                value={formik.values.contentDesc}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                error={formik.touched.contentDesc && Boolean(formik.errors.contentDesc)}
                                helperText={formik.touched.contentDesc && formik.errors.contentDesc}
                            />
                        </Grid>
                    </Grid>
                </Card>

                <Typography sx={{ mt: 5, mb: 1, color: '#0B0B0B', fontSize: '18px', fontWeight: '500' }}>
                    Media Upload
                </Typography>

                <Card sx={{ p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}>

                    <Box
                        sx={{
                            border: "2px dashed #13BECF",
                            borderRadius: "8px",
                            p: 3,
                            textAlign: "center",
                        }}
                    >
                        <img
                            src={Upload}
                            alt="Upload icon"
                            style={{ width: 50, marginBottom: 16 }}
                        />

                        <Typography sx={{ fontSize: '14px', color: '#0B0B0B', fontWeight: '400' }}>
                            Drag your file(s) or{" "}
                            <label
                                htmlFor="image-upload"
                                style={{
                                    fontSize: '14px',
                                    fontWeight: '400',
                                    color: '#13BECF',
                                    cursor: 'pointer',
                                }}
                            >
                                browse
                            </label>
                            <input
                                type="file"
                                accept="image/*,video/*"
                                onChange={handleFileChange}
                                style={{ display: 'none' }} // Hides the input
                                id="image-upload"
                            />
                        </Typography>

                        <Typography sx={{ fontSize: '14px', color: '#6D6D6D', fontWeight: '400' }}>
                            Max 10 MB files are allowed
                        </Typography>
                    </Box>

                    {selectedFile && <>
                        <Box sx={{ display: 'flex', gap: '8px', marginTop: '24px', padding: '8px', border: '1px solid #E7E7E7', borderRadius: '12px', alignItems: 'center' }}>

                            <img
                                src={selectedFile.fileType.includes('video') ? WhatsAppDemoVideo : `data:${selectedFile.fileType};base64,${selectedFile.base64}`}
                                style={{ width: '36px', height: '36px', cursor: 'pointer', borderRadius: '8px' }}
                                onClick={() => setOpenImage(true)}
                            />
                            <Box>
                                <Typography sx={{ fontSize: '14px', fontWeight: '600', color: '#13BECF' }}>Theproject-design. jpg</Typography>
                                <Typography sx={{ fontSize: '12px', fontWeight: '400', color: '#6D6D6D' }}>{`${selectedFile.fileSize} KB`}</Typography>
                            </Box>
                            <IconButton sx={{ marginLeft: 'auto', marginRight: '8px', cursor: 'pointer' }} onClick={handleRemoveFile}>
                                <DeleteIcon fontSize='small' />
                            </IconButton>
                        </Box>
                    </>}
                </Card>

                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        paddingY: "10px",
                        marginTop: "30px",
                    }}
                >
                    <Button
                        variant="outlined"
                        startIcon={<ArrowBackIcon />}
                        sx={{
                            borderRadius: "40px",
                            padding: "15px 30px",
                            // backgroundColor: "#445A68",
                            color: "#445A68",
                            fontSize: "18px",
                            fontWeight: "600",
                            border: "1px solid rgba(68, 90, 104, 1)",
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        variant="outlined"
                        endIcon={<ArrowForwardIcon />}
                        sx={{
                            borderRadius: "40px",
                            padding: "15px 30px",
                            color: "#FFFFFF",
                            background:
                                "linear-gradient(0deg, #13BECF 0%, #435D6B 100%)",
                            fontSize: "18px",
                            fontWeight: "600",
                            border: "1px solid #13BECF",
                        }}
                        type="submit"
                    >
                        Create Content
                    </Button>
                </Box>
            </Box>
            <ImagePreviewModal
                open={openImage}
                handleClose={handleClose}
                selectedFile={selectedFile}
            />
        </form>
    )
}

export default CreateContent