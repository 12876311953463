import React, { useState } from "react";
import { Box, Button, TextField, Typography, Breadcrumbs } from '@mui/material';
import PropTypes from 'prop-types';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Grid from '@mui/material/Grid2';
import CreateTemplateFinal from "./CreateTemplateFinal";
import CreateSmsCommunication from "./CreateSmsCommunication";
import CustomBreadcrumb from "../../components/CustomBreadcrumb/CustomBreadcrumb";

function CustomTabPanel(props) {
    const { children, value, index, ...other } = props;

    return (
        <div
            role="tabpanel"
            hidden={value !== index}
            id={`simple-tabpanel-${index}`}
            aria-labelledby={`simple-tab-${index}`}
            {...other}
        >
            {value === index && <Box sx={{ p: 3 }}>{children}</Box>}
        </div>
    );
}

CustomTabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.number.isRequired,
    value: PropTypes.number.isRequired,
};

function a11yProps(index) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


const ChooseTemplate = () => {
    const [value, setValue] = useState(0);

    const breadcrumb = [
        {
            title:'Home',
            href:'dashboard',
        },
        {
            title:'Template Editor',
            href:'template-list',
        },
        {
            title:'Create Template',
            href:'',
        }
    ];

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };
    return (

        <Box sx={{ width: '100%' }}>
            {/* <Breadcrumbs aria-label="breadcrumb">
                <Typography
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/dashboard"
                >
                    Home
                </Typography>
                <Typography sx={{ color: 'text.secondary', display: 'flex', alignItems: 'center' }}>
                    Template Editor
                </Typography>
                <Typography
                    sx={{ color: "text.primary", display: "flex", alignItems: "center" }}
                >
                    Create Template
                </Typography>
            </Breadcrumbs> */}

            <CustomBreadcrumb breadcrumb={breadcrumb}/>

            <Box sx={{ display: 'flex', marginTop: '16px' }}>
                <Typography sx={{ color: '#445A68', fontSize: '30px', fontWeight: '600' }}>Template Details</Typography>
            </Box>

            <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                <Tabs
                    value={value}
                    onChange={handleChange}
                    variant="fullWidth" // Makes the tabs take full width
                    sx={{
                        width: '100%',
                        '.MuiTabs-flexContainer': {
                            justifyContent: 'space-around',
                        },
                        '.MuiTabs-indicator': {
                            height: '5px',
                            backgroundColor: '#13BECF'
                        },

                        '.MuiButtonBase-root-MuiTab-root': {
                            '.Mui-selected': {
                                color: '',
                                backgroundColor: '#000'
                            }
                        }
                    }}
                >
                    <Tab
                        sx={{
                            flex: 1,
                            '&.Mui-selected': {
                                color: '#333333',
                                fontWeight: '600',
                                backgroundColor: '#F5F5F5',
                            },
                            textTransform:'capitalize !important '

                        }}
                        label="Whatsapp"
                        {...a11yProps(0)}
                    />
                    <Tab
                        sx={{

                            flex: 1,
                            '&.Mui-selected': {
                                color: '#333333',
                                fontWeight: '600',
                                backgroundColor: '#F5F5F5',
                            },
                            // textTransform:'capitalize !important '

                        }}
                        label="SMS"
                        {...a11yProps(1)}
                    />

                </Tabs>




            </Box>
            <CustomTabPanel value={value} index={0}>
                <CreateTemplateFinal />
            </CustomTabPanel>
            <CustomTabPanel value={value} index={1}>
                <CreateSmsCommunication />
            </CustomTabPanel>

        </Box>
    )
}


export default ChooseTemplate