import React from 'react'
import CustomModal from '../../../components/CustomModal/CustomModal'
import { Box } from '@mui/material';

const ImagePreviewModal = ({ open, handleClose, selectedFile }) => {

    console.log("selectedFileModal", selectedFile);

    return (
        <CustomModal
            open={open}
            onClose={() => {
                // setContentIds([selected]);
                handleClose();
            }}
            heading={"Uploaded Media Preview"}
            customWidth={"710px"}
        >
            <Box sx={{ display: 'flex', justifyContent: 'center' }}>

                {selectedFile?.fileType?.includes('video') ?
                    <>
                        <video controls width={'525px'}>
                            <source
                                src={`data:${selectedFile?.fileType};base64,${selectedFile?.base64}`}
                                type="video/mp4"
                            />
                        </video>
                    </> : <>
                        <img
                            src={`data:${selectedFile?.fileType};base64,${selectedFile?.base64}`}
                            alt="Full-size logo"
                            style={{ maxWidth: "345px", height: "auto" }}
                        />
                    </>}
            </Box>
        </CustomModal>
    )
}

export default ImagePreviewModal