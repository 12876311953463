import React, { useState } from "react";
import { FaArrowLeft, FaUserCircle, FaPaperPlane } from "react-icons/fa"; // Import icons
import "./SMSChatInterface.css"; // CSS

const SMSChatInterface = ({ formValues }) => {

  return (
    <div className="sms-chat-container">
      {/* Header */}
      <div className="sms-chat-header">
        <button className="back-button">
          <FaArrowLeft size={18} color="#fff" />
        </button>
        <FaUserCircle size={40} color="#fff" className="profile-icon" />
        <div className="header-info">
          <div className="contact-name">AIonos DXE Team</div>
          <div className="status">Online</div>
        </div>
      </div>

      {/* Chat Content */}
      <div className="sms-chat-content">
        {/* Incoming Message */}

        {formValues?.templateName?.trim() &&
          <div className="message-bubble incoming">
            <p className="message-text">
              {formValues?.templateName}
            </p>
          </div>}
        {formValues?.templateId?.trim() &&
          <div className="message-bubble incoming">
            <p className="message-text">
              {formValues?.templateId}
            </p>
          </div>}
        {formValues?.templateText?.trim() &&
          <div className="message-bubble incoming">
            <p className="message-text">
              {formValues?.templateText}
            </p>
          </div>}
        {formValues?.templateType?.trim() &&
          <div className="message-bubble incoming">
            <p className="message-text">
              {formValues?.templateType}
            </p>
          </div>}
        {/* Outgoing Message */}
        {/* <div className="message-bubble outgoing">
          <p className="message-text">{renderText()}</p>
          {formValues?.textBody?.length > characterLimit && (
            <button className="read-more-button" onClick={handleToggleExpand}>
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          )}
        </div> */}
      </div>

      {/* Message Input */}
      <div className="sms-message-input">
        <input type="text" placeholder="Type a message" />
        <button className="send-button">
          <FaPaperPlane size={18} color="#007AFF" />
        </button>
      </div>
    </div>
  );
};

export default SMSChatInterface;
