import * as React from 'react';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Link from '@mui/material/Link';

const CustomBreadcrumb = ({ breadcrumb }) => {

    function handleClick(event) {
        event.preventDefault();
        console.info('You clicked a breadcrumb.');
    }

    return (
        <div role="presentation">
            <Breadcrumbs aria-label="breadcrumb">
                {
                    breadcrumb?.map((item, index) => {

                        const currantPage = index === breadcrumb.length - 1;

                        return (
                            <Link
                                key={index}
                                underline={currantPage ? 'none' : 'hover'}
                                color={currantPage ? 'text.primary' : 'inherit'}
                                href={currantPage ? undefined : item.href}
                                aria-current={currantPage ? 'page' : undefined}
                                
                            >
                                {item?.title}
                            </Link>
                        )
                    })
                }
            </Breadcrumbs>
        </div>
    )
}

export default CustomBreadcrumb
