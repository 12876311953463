import React, { useEffect, useState } from "react";
import Table from "@mui/material/Table";
import { styled } from "@mui/material/styles";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import CustomModal from "../../../components/CustomModal/CustomModal";
import { useDispatch, useSelector } from "react-redux";
import {
    Box,
    CircularProgress,
    InputAdornment,
    TextField,
    IconButton,
    Menu,
    MenuItem,
    Checkbox,
    Grid2,
    Typography,
    Chip,
    Radio,
    FormControlLabel,
    Button,
    TablePagination,
} from "@mui/material";
import { Search } from "@mui/icons-material";
import CustomPagination from "../../../components/CustomPagination/CustomPagination";
import { fetchSMSTemplateSlice, fetchWhatsAppTemplateSlice } from "../../../redux/stepperSlice/stepper.slice";
import TemplateView from "./TemplateView";
import { toast } from "react-toastify";


const StyledTableRow = styled(TableRow)(({ theme }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: theme.palette.action.hover,
    },
    "&:last-child td, &:last-child th": {
        border: 0,
    },
}));

const BoxContainer = styled(Box)(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    gap: "4px",
}));

const TableHeadTitle = styled(Typography)(({ theme }) => ({
    fontSize: "16px",
    color: "#455967",
    fontWeight: "500",
    lineHeight: "1.5rem",
}));

const statusStyles = {
    active: {
        backgroundColor: "#e0f7e9",
        color: "#34a853",
    },
    offline: {
        backgroundColor: "#f5f5f5",
        color: "#9e9e9e",
    },
};

const TableDataTitle = styled(Typography)(({ theme }) => ({
    fontSize: "14px",
    color: "#455967",
    fontWeight: "500",
}));

const TemplateDetailsModal = ({ open, handleClose, classes, templateId, setTemplateId, templateDetails, formik }) => {

    const dispatch = useDispatch();
    const [selected, setSelected] = useState(null);
    const [searchQuery, setSearchQuery] = useState("");
    const [page, setPage] = useState(0);
    const [rowsPerPage, setRowsPerPage] = useState(5);
    const [openTemplate, setOpenTemplate] = useState(false);
    const [templateData, setTemplateData] = useState({});
    const tempType = formik.values.channel

    const handleClick = (event, templateId) => {

        setSelected(templateId === selected ? null : templateId);
    };

    const handleSelect = () => {

        if (!selected) {
            toast.error('Please select a template before proceeding!')
            return;
        }

        setTemplateId(selected);
        formik.setFieldValue("templateDetails", selected);
        handleClose();
    }

    const handleSearchChange = (event) => {
        setSearchQuery(event.target.value.toLowerCase());
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleViewTemplate = async (item) => {

        if (tempType === "SMS") {
            
            const result = await dispatch(fetchSMSTemplateSlice(item?.id)).unwrap();

            if (result) {
                setTemplateData(result);
                setOpenTemplate(true);
            }
            else {
                console.log("error");
            }
        }
        else{
            const result = await dispatch(fetchWhatsAppTemplateSlice(item?.templateId)).unwrap();

            if (result) {
                setTemplateData(result);
                setOpenTemplate(true);
            }
            else {
                console.log("error");
            }
        }

        setOpenTemplate(true);
    }

    const handleTemplateClose = () => {
        setOpenTemplate(false);
    }

    const filteredTemplates = templateDetails.filter((item) =>
        item.templateText.toLowerCase().includes(searchQuery)
    );

    const paginatedTemplates = filteredTemplates.slice(
        page * rowsPerPage,
        page * rowsPerPage + rowsPerPage
    );


    console.log("templateData",templateData)

    return (
        <>
            <CustomModal
                open={open}
                onClose={() => {
                    handleClose();
                }}
                heading={"Template Details"}
                button={true}
                customWidth={"910px"}
            >

                <Grid2 container sx={{ justifyContent: "end" }}>
                    <Grid2 size={{ xs: 12, md: 6 }}>
                        <TextField
                            variant="outlined"
                            placeholder="Search"
                            fullWidth
                            className={classes.searchBar}
                            sx={{ color: "#ECECEC" }}
                            value={searchQuery}
                            onChange={handleSearchChange}
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position="start">
                                        <Search />
                                    </InputAdornment>
                                ),
                            }}
                        />
                    </Grid2>
                </Grid2>

                <Grid2 container item size={{ md: 12 }} sx={{ justifyContent: 'end', mt: '15px' }}>
                    <Button variant='contained' sx={{ color: '#fff', fontSize: '14px', fontWeight: 500, backgroundColor: '#13BECF', padding: '8px 24px', borderRadius: '6px', background: 'linear-gradient(180deg, #15BACB 0%, #3E6775 100%)' }} onClick={() => { handleSelect() }}>
                        Save
                    </Button>
                </Grid2>



                <TableContainer
                    component={Paper}
                    sx={{
                        marginTop: "12px",
                        border: "1px solid #D4D4D4",
                        borderRadius: "12px",
                    }}
                >
                    <Table aria-label="campaign table">
                        <TableHead>
                            <TableRow>
                                <TableCell>
                                    {/* <FormControlLabel
                                        control={
                                            <Radio
                                                color="primary"
                                                checked={templateDetails?.length > 0 && selected === null} // If no rows selected, check the first one
                                                onChange={(e) => handleClick(e, null)} // Clear selection
                                            />
                                        }
                                        label=""
                                    /> */}
                                </TableCell>
                                <TableCell>
                                    <BoxContainer>
                                        <TableHeadTitle>Template Id</TableHeadTitle>
                                    </BoxContainer>
                                </TableCell>

                                <TableCell>
                                    <BoxContainer>
                                        <TableHeadTitle>Template Text</TableHeadTitle>
                                    </BoxContainer>
                                </TableCell>
                                <TableCell>
                                </TableCell>
                            </TableRow>
                        </TableHead>

                        <TableBody>
                            {paginatedTemplates.map((item, key) => {
                                const isItemSelected = selected === item.templateId; // Check if this item is selected

                                return (
                                    <StyledTableRow
                                        onClick={(event) => handleClick(event, item.templateId)}
                                        role="checkbox"
                                        aria-checked={isItemSelected}
                                        tabIndex={-1}
                                        key={item.templateId}
                                        selected={isItemSelected}
                                    >
                                        <TableCell>
                                            <FormControlLabel
                                                control={
                                                    <Radio
                                                        color="primary"
                                                        checked={isItemSelected} // Check if this item is selected
                                                        // eslint-disable-next-line no-restricted-globals
                                                        onChange={() => handleClick(event, item.templateId)} // Set the selected ID
                                                    />
                                                }
                                                label=""
                                            />
                                        </TableCell>
                                        <TableCell>
                                            <TableDataTitle>{item?.templateId}</TableDataTitle>
                                        </TableCell>
                                        <TableCell>
                                            <TableDataTitle>{item?.templateText}</TableDataTitle>
                                        </TableCell>
                                        <TableCell>
                                            <Chip
                                                label={"View Template"}
                                                onClick={() => handleViewTemplate(item)}
                                                sx={{
                                                    borderRadius: "18px",
                                                    padding: '8px',
                                                    marginBottom: "4px",
                                                    background: "#445A68E5",
                                                    cursor: 'pointer',
                                                    color: "#fff",
                                                    "& .MuiChip-deleteIcon": {
                                                        color: "white",
                                                        fontSize: "16px",
                                                    },
                                                    "& .MuiChip-deleteIcon:hover": {
                                                        backgroundColor: "transparent",
                                                        color: "white",
                                                    },
                                                }}
                                            />
                                        </TableCell>
                                    </StyledTableRow>
                                );
                            })}
                        </TableBody>
                    </Table>
                </TableContainer>

                <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
                    <Box></Box>
                    <CustomPagination
                        rowsPerPageOptions={[5, 10, 25]}
                        count={templateDetails.length}
                        rowsPerPage={rowsPerPage}
                        page={page}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />

                    {/* <Box> */}
                    <Typography
                        sx={{
                            fontSize: '19px',
                            fontWeight: '600',
                            background: 'linear-gradient(180deg, #13BECF 0%, #455967 100%)',
                            WebkitBackgroundClip: 'text',
                            color: 'transparent',
                        }}
                    >
                        {page * rowsPerPage + 1}-{Math.min((page + 1) * rowsPerPage, templateDetails.length)} <span style={{ color: '#455967' }}>of {templateDetails.length}</span>
                    </Typography>

                    {/* </Box> */}
                </Box>
            </CustomModal>

            <TemplateView open={openTemplate} handleClose={handleTemplateClose} tempType={tempType} templateData={templateData} />
        </>
    )
}

export default TemplateDetailsModal