import React, { useEffect, useState } from "react";
import WhatsAppDemoImage from '../../assets/Vector_1.svg'
import WhatsAppDemoVideo from '../../assets/DemoVideo.jpg'
import WhatsAppDemoPDF from '../../assets/WhatsAppDemoPDF.jpg'
// import "./ChatInterface.css"; // For CSS styling
import Attach from '../../assets/Attach.svg'
import Media from '../../assets/media.svg'
import Menu from '../../assets/menu.svg'
import MicroPhone from '../../assets/Microphone.svg'
import Phone from '../../assets/Phone.svg'
import Profile from '../../assets/Profile.svg'
import Smiley from '../../assets/smiley.svg'
import Video from '../../assets/Video.svg'
import Back from '../../assets/Back Button.svg'
import ReplyIcon from '@mui/icons-material/Reply';


const ChatInterface = ({ formValues, selectedValue, selectedFile, headerImage, selectedFilePreview }) => {
  const [isExpanded, setIsExpanded] = useState(false);
  const characterLimit = 150;
  console.log('selectedValue', selectedValue)
  const renderText = () => {
    const text = formValues?.textBody || ''; // Safely access textBody
    // If expanded, return the full text
    if (isExpanded) {
      return text;
    }
    // If collapsed, return truncated text
    return text.length > characterLimit ? `${text.substring(0, characterLimit)}...` : text;
  };

  useEffect(() => {
    if (!formValues?.textBody || formValues?.textBody.trim() === "") {
      setIsExpanded(false);
    }
  }, [formValues?.textBody]);


  console.log("formValues", formValues)

  useEffect(() => {
    console.log("textBody", formValues);
  }, [formValues]);

  const isBlobUrl = (url) => url && url.startsWith("blob:");


  const handleToggleExpand = () => {
    setIsExpanded(!isExpanded);
  };

  console.log("headerImage", headerImage)
  console.log("selectedFilePreview", selectedFilePreview)

  return (
    <div className="chat-container" style={{position:'fixed'}}>
      {/* Header */}
      <div className="chat-header">
        <div className="header-left">
          <button className="back-button">
            <img src={Back} />
          </button>
          <img
            src={Profile}
            alt="profile"
            className="profile-image"
          />
          <div className="profile-info">
            <div className="profile-name">AIonos DXE Team</div>
            <div className="status">Online</div>
          </div>
        </div>
        <div className="header-icons">
          <button className="icon-button">
            <img src={Video}></img>
          </button>
          <button className="icon-button">
            <img src={Phone}></img>
          </button>
          <button className="icon-button">
            <img src={Menu} />
          </button>
        </div>
      </div>

      {/* Message Bubble */}

      <div className="chat-content">
        <div className="message-bubble">
          {/* <div className="message-image-placeholder">
            {selectedValue === 'image' ?
              <img
                src={isBlobUrl(selectedFilePreview) ? headerImage?.url : WhatsAppDemoImage}
                alt="attachment"
                className="message-image"
              />
              : selectedValue === 'video' && selectedFilePreview !== null ?
                <>
                  <video width="320" height="240" controls>
                    <source
                      src={headerImage?.url}
                      type="video/mp4"
                    />
                  </video>
                </>
                : selectedValue === 'document' ? '' : <></>}
          </div>

          {selectedValue === "text" && (
            <div className="message-image-placeholder">
              <p>{formValues?.header}</p>
            </div>
          )} */}
          {/* <p className="message-text">{formValues?.textBody}</p> */}

          <div className="message-image-placeholder">
            {selectedValue === "image" && (
              <img
                src={isBlobUrl(selectedFilePreview) ? headerImage?.url : WhatsAppDemoImage}
                alt="Attachment"
                className="message-image"
              />
            )}
            {selectedValue === "video" && headerImage?.url ? (
              <video className="message-image" controls>
                <source
                  src={headerImage?.url}
                  type="video/mp4" />
              </video>
            ) : selectedValue === "video" ?
              <>
                <img
                  src={WhatsAppDemoVideo}
                  alt="Attachment"
                  className="message-image"
                />
              </> : <></>}

            {selectedValue === "document" && headerImage?.url ? (
              <>
                <iframe seamless className="message-image" src={headerImage?.url}>
                  <p>Your browser does not support iframes.</p>
                </iframe>
              </>
            ) : selectedValue === "document" ?
              <>
                <img
                  src={WhatsAppDemoPDF}
                  alt="Attachment"
                  className="message-image"
                />
              </> : <></>}
          </div>

          {selectedValue === "text" && (
            <div className="message-image-placeholder">
              <p>{formValues?.header}</p>
            </div>
          )}

          <p className={`message-text ${isExpanded ? "expanded" : "collapsed"}`}>
            {renderText()}
          </p>
          {formValues?.textBody?.length > characterLimit && (
            <button
              className="read-more-button"
              onClick={handleToggleExpand}
            >
              {isExpanded ? "Read Less" : "Read More"}
            </button>
          )}

          {
            formValues?.footerText?.length > 0 && (
              <p className="footer-text">
                {formValues?.footerText}
              </p>
            )
          }

          {formValues?.buttonRows?.length > 0 && formValues?.buttonRows[0]?.buttonText !== '' ?
            <div className="message-links">
              {formValues?.buttonRows?.map((item, index) => {

                return (
                  <a href={item.websiteUrl} className="learn-more-link" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
                    <ReplyIcon />
                    {item?.buttonText}
                  </a>
                )
              })}
            </div>
            : <></>}
        </div>
      </div>
      {/* Message Input */}

      <div className="message-input-container">
        <div class="input">
          <span class="prefix">
            <img src={Smiley} />
          </span>
          <input placeholder="Type a message" />
          <span class="suffix"><img src={Attach} /></span>
          <span class="suffix"><img src={Media} /></span>
        </div>
        <button className="btn">
          <img src={MicroPhone} />
        </button>
      </div>
    </div>
  );
};

export default ChatInterface;



