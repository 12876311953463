import React, { useState } from 'react';
import { Formik, Field, Form } from 'formik';
import { Box, Card, Grid, Typography, TextField, Button, Menu, MenuItem, Chip, Divider, IconButton, Autocomplete } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import DeleteIcon from '@mui/icons-material/Delete';

// Dummy data for dropdowns
const actionTypeList = ['Link', 'Call', 'Message']; // Replace with actual action types
const urlTypeList = ['URL1', 'URL2']; // Replace with actual URL types

const CreateTemplate = () => {
  const initialValues = {
    templateName: '',
    templateLanguage: '',
    templateStatus: '',
    templateCategory: '',
    templateHeader: '',
    textBody: '',
    footerText: '',
    buttonRows: [{ id: Date.now(), buttonText: '', actionType: '', websiteUrl: '', urlType: '' }],
  };

  const [anchorE2, setAnchorE2] = useState(null);
  const [selectedValue2, setSelectedValue2] = useState("");
  const [formValues, setFormValues] = useState(initialValues);


  // Handle button type selection
  const handleButtonTypeClick = (event) => {

    setAnchorE2(event.currentTarget);
  };

  const handleButtonTypeClose = (value) => {
    console.log('click  event.currentTarget-------------------------',value)

    setAnchorE2(null);
    if (value) {
      setSelectedValue2(value);
    }
  };

  // Handle adding a new button row
  const handleAddButtonRow = (setFieldValue) => {
    console.log('click0-------------------------')
    const newButtonRow = { id: Date.now(), buttonText: '', actionType: '', websiteUrl: '', urlType: '' };
    setFieldValue('buttonRows', [...initialValues.buttonRows, newButtonRow]); // Add new button row
  };

  // Handle deleting a button row
  const handleDeleteButtonRow = (index, setFieldValue) => {
    setFieldValue('buttonRows', initialValues.buttonRows.filter((_, idx) => idx !== index)); // Remove button row by index
  };


 
  const handleFormChange = (e) => {
    const { name, value } = e.target;
    setFormValues((prevValues) => ({
      ...prevValues,
      [name]: value,
    }));
    console.log('Current Form Values:', formValues); // Log the current form values
  };



  return (
    <Box>
      <Formik
        initialValues={initialValues}
        onSubmit={(values) => {
          console.log('Form Submitted', values);
        }}
      
      >
        {({ values, handleChange, handleBlur, setFieldValue, errors, touched }) => (
          <Form>
            <Grid container mt={4} spacing={2}>
              <Grid item md={8}>
                <Card sx={{ p: 3, borderRadius: "15px", boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)" }}>
                  <Grid container spacing={3}>
                    <Grid item md={6}>
                      <Typography>Template Name <span style={{ color: "#FF0000" }}>*</span></Typography>
                      <Field
                        as={TextField}
                        name="templateName"
                        value={values.templateName}
                        onChange={(e) => {
                          handleChange(e); // Update Formik's internal state
                          handleFormChange(e); // Update your custom formValues state
                        }}
                        onBlur={handleBlur}
                        fullWidth
                        error={Boolean(touched.templateName && errors.templateName)}
                        helperText={touched.templateName && errors.templateName}
                      />
                    </Grid>

                    <Grid item md={6}>
                      <Typography>Template Language <span style={{ color: "#FF0000" }}>*</span></Typography>
                      <Field
                        name="templateLanguage"
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={['English', 'Spanish', 'French']} // Replace with actual languages
                            value={values.templateLanguage}
                            onChange={(e, value) => {
                              setFieldValue("templateLanguage", value);
                              handleFormChange({ target: { name: 'templateLanguage', value } });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Select Language" />
                            )}
                          />
                        )}
                      />
                    </Grid>

                    <Grid container item md={12} spacing={3}>
                      <Grid item md={6}>
                        <Typography>Template Status <span style={{ color: "#FF0000" }}>*</span></Typography>
                        <Field
                          name="templateStatus"
                          render={({ field }) => (
                            <Autocomplete
                              {...field}
                              options={['Active', 'Inactive']} // Replace with actual statuses
                              value={values.templateStatus}
                             
                              onChange={(e, value) => {
                                setFieldValue("templateStatus", value);
                                handleFormChange({ target: { name: 'templateStatus', value } });
                              }}
                              renderInput={(params) => (
                                <TextField {...params} placeholder="Select Template Status" />
                              )}
                            />
                          )}
                        />
                      </Grid>
                    </Grid>

                    <Grid item md={12}>
                      <Typography>Template Category <span style={{ color: "#FF0000" }}>*</span></Typography>
                      <Field
                        name="templateCategory"
                        render={({ field }) => (
                          <Autocomplete
                            {...field}
                            options={['Marketing', 'Transactional']} // Replace with actual categories
                            value={values.templateCategory}
                            // onChange={(e, value) => setFieldValue("templateCategory", value)}

                            onChange={(e, value) => {
                              setFieldValue("templateCategory", value);
                              handleFormChange({ target: { name: 'templateCategory', value } });
                            }}
                            renderInput={(params) => (
                              <TextField {...params} placeholder="Select Template Category" />
                            )}
                          />
                        )}
                      />
                    </Grid>
                  </Grid>
                </Card>
              </Grid>

              {/* Template Body Section */}
              <Grid container my={3}>
                <Grid item md={12}>
                  <Typography sx={{ color: "#455967", fontWeight: "500", fontSize: "20px" }}>
                    Template Body
                  </Typography>
                  <Typography sx={{ color: "#A8A8A8", fontWeight: "400", fontSize: "16px" }}>
                    Add Text body for your Header
                  </Typography>
                  <Card
                    sx={{
                      p: 3,
                      borderRadius: "15px",
                      boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)",
                      marginTop: "24px",
                    }}
                  >
                    <Grid container>
                      <Grid item md={12}>
                        <Typography>Text Body <span style={{ color: "#FF0000" }}>*</span></Typography>
                        <Field
                          as={TextField}
                          name="textBody"
                          value={values.textBody}
                          onChange={handleChange}
                          onBlur={handleBlur}
                          fullWidth
                          multiline
                          rows={4}
                          error={Boolean(touched.textBody && errors.textBody)}
                          helperText={touched.textBody && errors.textBody}
                        />
                      </Grid>
                    </Grid>
                  </Card>
                </Grid>
              </Grid>

              {/* Template Buttons Section */}
              <Grid container my={3}>
                <Grid item md={12}>
                  <Typography sx={{ color: "#455967", fontWeight: "500", fontSize: "20px" }}>
                    Template Buttons
                  </Typography>
                  <Typography sx={{ color: "#A8A8A8", fontWeight: "400", fontSize: "16px" }}>
                    Choose Button Type that lets your customer respond to your message or take action
                  </Typography>

                  <Card sx={{ p: 3, borderRadius: "15px", boxShadow: "0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)", marginTop: "24px" }}>
                    <Grid container>
                      <Grid item md={12} display={"flex"} justifyContent={"space-between"} alignItems={"center"}>
                        <Typography sx={{ color: "#A8A8A8", fontWeight: "400", fontSize: "16px" }}>
                          Choose Button Type
                        </Typography>

                        <Chip
                          sx={{ fontSize: "16px", backgroundColor: "#445A68", color: "#FFF", height: "36px", borderRadius: "20px" }}
                          label="Button Type"
                          onClick={handleButtonTypeClick}
                          deleteIcon={<ArrowDropDownIcon sx={{ fill: "#FFF" }} />}
                        />

                        <Menu anchorEl={anchorE2} open={Boolean(anchorE2)} onClose={() => handleButtonTypeClose(null)}>
                          <MenuItem onClick={() => handleButtonTypeClose("callToAction")}>Call To Action</MenuItem>
                          <MenuItem onClick={() => handleButtonTypeClose("quickReply")}>Quick Reply</MenuItem>
                          <MenuItem onClick={() => handleButtonTypeClose("none")}>None</MenuItem>
                        </Menu>
                      </Grid>

                      {selectedValue2 === "callToAction" && values.buttonRows.map((row, index) => (
                        <Grid container key={row.id} spacing={2} sx={{ mt: 2 }}>
                          <Grid item md={12}><Divider /></Grid>
                          <Grid item md={3}>
                            <Typography>Action Type</Typography>
                            <Autocomplete
                              options={actionTypeList}
                              value={row.actionType}
                              onChange={(e, value) => setFieldValue(`buttonRows[${index}].actionType`, value)}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Grid>
                          <Grid item md={2.5}>
                            <Typography>Button Text</Typography>
                            <TextField
                              value={row.buttonText}
                              onChange={(e) => setFieldValue(`buttonRows[${index}].buttonText`, e.target.value)}
                            />
                          </Grid>
                          <Grid item md={2.5}>
                            <Typography>URL Type</Typography>
                            <Autocomplete
                              options={urlTypeList}
                              value={row.urlType}
                              onChange={(e, value) => setFieldValue(`buttonRows[${index}].urlType`, value)}
                              renderInput={(params) => <TextField {...params} />}
                            />
                          </Grid>
                          <Grid item md={3}>
                            <Typography>Website URL</Typography>
                            <TextField
                              value={row.websiteUrl}
                              onChange={(e) => setFieldValue(`buttonRows[${index}].websiteUrl`, e.target.value)}
                            />
                          </Grid>
                          <Grid item md={1} sx={{ display: "flex", alignItems: "end" }}>
                            <IconButton onClick={() => handleDeleteButtonRow(index, setFieldValue)}>
                              <DeleteIcon sx={{ fill: "#FA5A5A" }} />
                            </IconButton>
                          </Grid>
                        </Grid>
                      ))}

                      {selectedValue2 === "callToAction" && (
                        <Grid item md={12} sx={{ display: "flex", justifyContent: "center" }}>
                          <Button
                            variant="outlined"
                            startIcon={<AddIcon />}
                            onClick={(e) => handleAddButtonRow(setFieldValue,e)}
                          >
                            Add Button
                          </Button>
                        </Grid>
                      )}
                    </Grid>
                  </Card>
                </Grid>
              </Grid>

              {/* Submit buttons */}
              <Box sx={{ display: "flex", justifyContent: "center", gap: "10px", paddingY: "10px" }}>
                <Button variant="outlined" sx={{ borderRadius: "40px", padding: "15px 30px", backgroundColor: "#445A68", color: "#FFFFFF" }}>
                  Back
                </Button>
                <Button
                  variant="outlined"
                  sx={{
                    borderRadius: "40px",
                    padding: "15px 30px",
                    color: "#FFFFFF",
                    background: "linear-gradient(0deg, #13BECF 0%, #435D6B 100%)",
                  }}
                  type="submit"
                >
                  Submit
                </Button>
              </Box>
            </Grid>
          </Form>
        )}
      </Formik>
    </Box>
  );
};

export default CreateTemplate;

 



