import React, { useEffect, useState } from "react";
import CustomModal from "../../../components/CustomModal/CustomModal";
import WhatsAppDemoImage from '../../../assets/Vector_1.svg'
import WhatsAppDemoVideo from '../../../assets/DemoVideo.jpg'
import WhatsAppDemoPDF from '../../../assets/WhatsAppDemoPDF.jpg'
import Attach from '../../../assets/Attach.svg'
import Media from '../../../assets/media.svg'
import Menu from '../../../assets/menu.svg'
import MicroPhone from '../../../assets/Microphone.svg'
import Phone from '../../../assets/Phone.svg'
import Profile from '../../../assets/Profile.svg'
import Smiley from '../../../assets/smiley.svg'
import Video from '../../../assets/Video.svg'
import Back from '../../../assets/Back Button.svg'
import ReplyIcon from '@mui/icons-material/Reply';
import { FaArrowLeft, FaUserCircle, FaPaperPlane } from "react-icons/fa";
import "../../CreateTemplate/SMSChatInterface.css";


const dataToShow = [
    {
        "type": "HEADER",
        "format": "IMAGE"
    },
    {
        "type": "BODY",
        "text": "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum."
    },
    {
        "type": "FOOTER",
        "text": "Testing New"
    },
    {
        "type": "BUTTONS",
        "buttons": [
            {
                "type": "URL",
                "text": "Check",
                "url": "http://www.url.com/"
            }
        ]
    }
];

const smsData = {
    "id": "67441c89ff0d4f7233c331b3",
    "orgId": "66ffacd73030fd2b301f0c07",
    "templateId": "template-act-12",
    "templateCategory": "MARKETING",
    "templateText": "This is a sample template text-act-12.",
    "templateName": "my-temp-act-12",
    "status": "APPROVED",
    "media": null,
    "templateType": "SMS",
    "categoryId": "id-act-12",
    "createdOn": null,
    "createdBy": "Mukti",
    "updatedOn": null,
    "updatedBy": null,
    "deleted": false
}

const TemplateView = ({ open, handleClose, tempType, templateData }) => {

    const [isExpanded, setIsExpanded] = useState(false);
    const characterLimit = 150;
    const renderText = () => {
        const text = dataToShow[1].text || ''; // Safely access textBody
        // If expanded, return the full text
        if (isExpanded) {
            return text;
        }
        // If collapsed, return truncated text
        return dataToShow[1].text.length > characterLimit ? `${text.substring(0, characterLimit)}...` : text;
    };

    const handleToggleExpand = () => {
        setIsExpanded(!isExpanded);
    };

    // useEffect(() => {
    //     if (!templateData?.textBody || templateData?.textBody.trim() === "") {
    //         setIsExpanded(false);
    //     }
    // }, [templateData?.textBody]);

    // console.log("dataToShow[2]?.buttons",);
    // console.log('selectedValue', selectedValue)c

    return (
        <CustomModal
            open={open}
            onClose={() => {
                handleClose();
            }}
            heading={"Template Preview"}
            customWidth={"710px"}
        >

            {/* WhatsApp UI */}

            {
                tempType === "WHATSAPP" || tempType === 'Whatsapp' ?
                    <>
                        <div className="chat-container" style={{ margin: 'auto' }}>
                            {/* Header */}
                            <div className="chat-header">
                                <div className="header-left">
                                    <button className="back-button">
                                        <img src={Back} />
                                    </button>
                                    <img
                                        src={Profile}
                                        alt="profile"
                                        className="profile-image"
                                    />
                                    <div className="profile-info">
                                        <div className="profile-name">AIonos DXE Team</div>
                                        <div className="status">Online</div>
                                    </div>
                                </div>
                                <div className="header-icons">
                                    <button className="icon-button">
                                        <img src={Video}></img>
                                    </button>
                                    <button className="icon-button">
                                        <img src={Phone}></img>
                                    </button>
                                    <button className="icon-button">
                                        <img src={Menu} />
                                    </button>
                                </div>
                            </div>

                            {/* Message Bubble */}

                            <div className="chat-content">
                                <div className="message-bubble">

                                    <div className="message-image-placeholder">
                                        <img
                                            src={WhatsAppDemoImage}
                                            alt="Attachment"
                                            className="message-image"
                                        />
                                    </div>

                                    <p className={`message-text ${isExpanded ? "expanded" : "collapsed"}`}>
                                        {renderText()}
                                    </p>

                                    {dataToShow[1].text?.length > characterLimit && (
                                        <button
                                            className="read-more-button"
                                            onClick={handleToggleExpand}
                                        >
                                            {isExpanded ? "Read Less" : "Read More"}
                                        </button>
                                    )}

                                    <p className="footer-text">
                                        {dataToShow[2].text}
                                    </p>

                                    {dataToShow[3]?.buttons?.length > 0 ?
                                        <div className="message-links">
                                            {dataToShow[3]?.buttons?.map((item, index) => {

                                                return (
                                                    <a href={item.url} className="learn-more-link" style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '2px' }}>
                                                        <ReplyIcon />
                                                        {item?.text}
                                                    </a>
                                                )
                                            })}
                                        </div>
                                        : <></>}
                                </div>
                            </div>
                            {/* Message Input */}

                            <div className="message-input-container">
                                <div class="input">
                                    <span class="prefix">
                                        <img src={Smiley} />
                                    </span>
                                    <input placeholder="Type a message" />
                                    <span class="suffix"><img src={Attach} /></span>
                                    <span class="suffix"><img src={Media} /></span>
                                </div>
                                <button className="btn">
                                    <img src={MicroPhone} />
                                </button>
                            </div>
                        </div>


                    </>
                    : <>

                        <div className="sms-chat-container" style={{ margin: 'auto' }}>
                            {/* Header */}
                            <div className="sms-chat-header">
                                <button className="back-button">
                                    <FaArrowLeft size={18} color="#fff" />
                                </button>
                                <FaUserCircle size={40} color="#fff" className="profile-icon" />
                                <div className="header-info">
                                    <div className="contact-name">AIonos DXE Team</div>
                                    <div className="status">Online</div>
                                </div>
                            </div>

                            {/* Chat Content */}
                            <div className="sms-chat-content">
                                {/* Incoming Message */}

                                {templateData?.templateName?.trim() &&
                                    <div className="message-bubble incoming">
                                        <p className="message-text">
                                            {templateData?.templateName}
                                        </p>
                                    </div>}
                                {templateData?.templateId?.trim() &&
                                    <div className="message-bubble incoming">
                                        <p className="message-text">
                                            {templateData?.templateId}
                                        </p>
                                    </div>}
                                {templateData?.templateText?.trim() &&
                                    <div className="message-bubble incoming">
                                        <p className="message-text">
                                            {templateData?.templateText}
                                        </p>
                                    </div>}
                                {templateData?.templateCategory?.trim() &&
                                    <div className="message-bubble incoming">
                                        <p className="message-text">
                                            {templateData?.templateCategory}
                                        </p>
                                    </div>}


                                {/* Outgoing Message */}
                                {/* <div className="message-bubble outgoing">
                                    <p className="message-text">{renderText()}</p>
                                    {templateData?.textBody?.length > characterLimit && (
                                        <button className="read-more-button" onClick={handleToggleExpand}>
                                            {isExpanded ? "Read Less" : "Read More"}
                                        </button>
                                    )}
                                </div> */}
                            </div>

                            {/* Message Input */}
                            <div className="sms-message-input">
                                <input type="text" placeholder="Type a message" />
                                <button className="send-button">
                                    <FaPaperPlane size={18} color="#007AFF" />
                                </button>
                            </div>
                        </div>
                    </>
            }
        </CustomModal>
    )
}

export default TemplateView