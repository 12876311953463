import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import en from './en.json';
import id from './id.json';

i18n
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en },
            id: { translation: id },
        },
        fallbackLng: 'en', // Fallback language if detection fails
        debug: true, // Set to false in production
        interpolation: {
            escapeValue: false, // React already protects from XSS
        },
    });

export default i18n;
