import axiosInstance from "../../components/utils/axiosInstance";
import { toast } from "react-toastify";
import axios from "axios";

// For Demo Purpose 

const idAuthUrl = 'https://34.8.188.168.nip.io';

export const fetchIpiFicationPostThunk = async (data) => {

    try {
        const initialResponse = await axios.post(
            `${idAuthUrl}/api/v1/ipification/generateUrl`,
            data,
            { validateStatus: null }
        );

        // if (initialResponse.status === 302 && initialResponse.headers.location) {
        //   const redirectResponse = await axios.get(initialResponse.headers.location);
        //   return redirectResponse.data;
        // }

        console.log("Redirecting to:", initialResponse.data);

        return initialResponse.data;


    } catch (error) {
        return toast.error(error?.response?.data?.error);
    }
}

export const fetchIpiFicationGetThunk = async (data) => {
    console.log("dataaa---", data);
    try {
        const response = await axios.get(`${idAuthUrl}/api/v1/id-auth/status/${data.requestId}`);

        return response.data


    } catch (error) {
        return toast.error(error?.response?.data?.error);
    }
}

export const fetchIpiFicationRespPostThunk = async (data) => {

    try {
        // const response = await axios.get(`${data?.redirectionUrl}`, data?.urlData);
        // const response = await axios.get('https://api.stage.ipification.com/auth/realms/ipification/protocol/openid-connect/auth?response_type=code&redirect_uri=https://34.54.18.161.nip.io/ipification/api/v1/ipification/callback&client_id=5b4dcd2613944553b42124ab6d481619&scope=openid%20ip:phone_verify&state=1d445106-a87f-4c26-9e4c-9304b98eab54&login_hint=999123456789');
        // console.log("resp-----",response)
        // return response.data
        // window.open(data?.redirectionUrl, "_blank", "noreferrer");

    } catch (error) {
        return toast.error(error?.response?.data?.error);
    }
}


export const fetchIpiFicationAuthPostThunk = async (data) => {

    console.log("Data---", data)

    try {
        const initialResponse = await axios.post(
            `${idAuthUrl}/api/v1/id-auth/authenticate?clientId=5b4dcd2613944553b42124ab6d481619`, data,
            {
                validateStatus: null
            }
        );

        // if (initialResponse.status === 302 && initialResponse.headers.location) {
        //   const redirectResponse = await axios.get(initialResponse.headers.location);
        //   return redirectResponse.data;
        // }

        console.log("Redirecting to:", initialResponse.data);

        return initialResponse.data;


    } catch (error) {
        return toast.error(error?.response?.data?.error);
    }
}

export const VerifyOtpThunk = async (data) => {

    try {
        const response = await axios.post(`${idAuthUrl}/api/v1/id-auth/verifyOtp?txnId=${data.txnId}`, data, {})

        return response.data

    } catch (error) {

        return toast.error(error?.response?.data?.error);
    }
}

export const resendOtpThunk = async (data) => {

    try {
        const response = await axios.post(
            `${idAuthUrl}/api/v1/id-auth/sms/resendOtp?txnId=${data.txnId}`,
            data
        );
        return response.data

    } catch (error) {

        return toast.error(error?.response?.data?.error);
    }
}


export const authIpificationLoginThunk = async (data) => {

    const credentials = btoa(`${data.username}:${data.password}`);
    const auth = {
      'grant_type': 'client_credentials'
    }
  
  
    try {
      const response = await axios.post(
        `${idAuthUrl}/oauth2-cc/jwt?apikey=IFRpCveuQ9lBuOuucAbjES3nWkSaUodsTqAdWoBNwiThRrZk`,
        auth,
        {
          headers: {
            Authorization: `Basic ${credentials}`,
            'Content-Type': 'application/x-www-form-urlencoded', 
          },
        }
      );
  
      console.log('Login successful:', response.data);
      return response.data;
    } catch (error) {
      console.error('Login failed:', error.response ? error.response.data : error.message);
      throw error;
    }
  
  }