// import React from "react";
// import { Switch as MUISwitch, styled } from "@mui/material";

// const CustomSwitch = styled(MUISwitch)(({ theme }) => ({
//     width: 56,
//     height: 26,
//     padding: 0,
//     "& .MuiSwitch-switchBase": {
//         padding: 1,
//         "&.Mui-checked": {
//             transform: "translateX(30px)",
//             color: theme.palette.common.white,
//             "& + .MuiSwitch-track": {
//                 backgroundColor: '#76EE59',
//                 opacity: 1,
//                 border: "none",
//             },
//         },
//     },
//     "& .MuiSwitch-thumb": {
//         width: 24,
//         height: 24,
//     },
//     "& .MuiSwitch-track": {
//         borderRadius: 13,
//         backgroundColor: theme.palette.grey[400],
//         opacity: 1,
//         transition: theme.transitions.create(["background-color", "border"]),
//     },
// }));

// const CustomSwitchComponent = (props) => <CustomSwitch disableRipple {...props} />;

// export default CustomSwitchComponent;


import React from "react";
import { Switch as MuiSwitch, Typography, styled } from "@mui/material";

const StyledSwitchRoot = styled("div")(({ theme }) => ({
    display: "flex",
    alignItems: "center",
    position: "relative",
}));

const StyledLabel = styled(Typography)(({ theme }) => ({
    fontWeight: 400,
    color: theme.palette.text.primary,
    position: "absolute",
    zIndex: 1,
    pointerEvents: "none",
}));

const StyledSwitch = styled(MuiSwitch)(({ theme }) => ({
    width: 100, 
    height: 34,
    padding: 0,
    "& .MuiSwitch-switchBase": {
        padding: 2,
        "&.Mui-checked": {
            transform: "translateX(66px)", 
            color: theme.palette.common.white,
            "& + .MuiSwitch-track": {
                // backgroundColor: "#76EE59 !important",
                background: 'linear-gradient(270deg, #A8F59A 0%, #76EE59 50%, #66B74A 100%)',
                opacity: 1,
            },
        },
    },
    "& .MuiSwitch-thumb": {
        width: 30,
        height: 30,
    },
    "& .MuiSwitch-track": {
        borderRadius: 17,
        background:'linear-gradient(270deg, #FE4444 0%, #982929 100%)',
        opacity: 1,
        transition: theme.transitions.create(["background-color", "border"]),
    },
}));

const CustomSwitch = ({ checked, ...props }) => {
    return (
        <StyledSwitchRoot sx={{width:'100px !important'}}>
            {checked && (
                <StyledLabel sx={{ left: 8, color:'#FFF', fontSize:'12px', fontWeight:'400', textAlign:'center' }}>
                    ACTIVE
                </StyledLabel>
            )}
            <StyledSwitch checked={checked} disableRipple {...props} />
            {!checked && (
                <StyledLabel sx={{ right: 8, color:'#FFF', fontSize:'12px', fontWeight:'400', textAlign:'center' }}>
                    INACTIVE
                </StyledLabel>
            )}
        </StyledSwitchRoot>
    );
};

// Switch.propTypes = {
//     checked: PropTypes.bool.isRequired,
// };

export default CustomSwitch;


