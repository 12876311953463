import React, { useRef, useState } from 'react'
import { Box, Breadcrumbs, Card, TextField, Typography, Chip, Menu, MenuItem, IconButton, Button, Autocomplete } from '@mui/material'
import Grid from "@mui/material/Grid2";
import { makeStyles } from "@mui/styles";
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { FormatBold, FormatItalic, FormatUnderlined } from '@mui/icons-material';
import AddIcon from '@mui/icons-material/Add';
import WhatsAppImage from '../../assets/watsapp Demo Screen 1.svg'
import ArrowBackIcon from "@mui/icons-material/ArrowBack";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import WhatsAppDemoImage from '../../assets/Vector_1.svg'
import Divider from '@mui/material/Divider';
import DeleteIcon from "@mui/icons-material/Delete";
import ChatInterface from './ChatUi';


const useStyles = makeStyles((theme) => ({
    textField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            border: "0px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
        "& .MuiFormHelperText-root": {
            marginLeft: "0px",
        },
    },
    selectField: {
        width: "100%",
        "& .MuiOutlinedInput-root": {
            backgroundColor: "#fff",
            borderRadius: "10px",
            // border: "1px solid #C5C5C5",
            height: "60px", // Updated height to 60px
            background: "#FFF",
        },
        "& .MuiInputBase-input::placeholder": {
            backgroundColor: "#FFF", // Ensuring placeholder background is white
        },
    },
    label: {
        color: "#7C7C7C",
        fontWeight: 500,
        marginBottom: "2px",
        fontSize: "18px !important",
    },
}));

const language = [
    {
        label: 'English',
        value: 'English',
    }
]

const statusList = [
    {
        label: 'Active',
        value: 'active'
    },
    {
        label: 'Inactive',
        value: 'inactive'
    }
]

const categoryList = [
    {
        label: 'Marketing',
        value: 'marketing'
    },
    {
        label: 'One Time Passwords',
        value: 'otp'
    },
    {
        label: 'Transactional',
        value: 'transactional'
    },
]

const actionTypeList = [
    {
        label: 'Website',
        value: 'website'
    }
]

const urlTypeList = [
    {
        label: 'Static',
        value: 'static'
    }
]


const CreateTemplateOld = () => {

    const initialValue = {
        templateName: '',
        templateLanguage: '',
        templateStatus: '',
        templateCategory: '',
        templateHeader: '',
        textBody: '',
        footerText: '',
        actionType: '',
        buttonText: '',
    }

    const classes = useStyles();
    const [anchorEl, setAnchorEl] = useState(null);
    const [selectedValue, setSelectedValue] = useState('');
    const [anchorE2, setAnchorE2] = useState(null);
    const [selectedValue2, setSelectedValue2] = useState('');
    const [selectedImage, setSelectedImage] = useState(null);

    // Handle image upload
    const handleImageChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            setSelectedImage(URL.createObjectURL(file));
        }
    };

    // Handle image removal
    const handleRemoveImage = () => {
        setSelectedImage(null);
    };


    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = (value) => {
        setAnchorEl(null);
        setSelectedValue(value);
    };

    const handleButtonTypeClick = (event) => {
        setAnchorE2(event.currentTarget);
    };

    const handleButtonTypeClose = (value) => {
        setAnchorE2(null);
        if (value) {
            setSelectedValue2(value);
        }
    };

    return (
        <Box>
            <Breadcrumbs aria-label="breadcrumb">
                <Typography
                    underline="hover"
                    sx={{ display: 'flex', alignItems: 'center' }}
                    color="inherit"
                    href="/dashboard"
                >

                    Home
                </Typography>

                <Typography
                    sx={{ color: 'text.primary', display: 'flex', alignItems: 'center' }}
                >

                    Create Template
                </Typography>
            </Breadcrumbs>

            <Box sx={{ display: 'flex', marginTop: '16px' }}>
                <Typography sx={{ color: '#445A68', fontSize: '30px', fontWeight: '600', }}>Template Details</Typography>
            </Box>

            <form>
                <Grid container mt={4} spacing={2}>
                    <Grid size={{ md: 8 }}>
                        <Card sx={{ p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}>
                            <Grid container spacing={3}>
                                <Grid size={{ md: 6 }}>
                                    <Typography className={classes.label}>Template Name <span style={{ color: '#FF0000' }}>*</span></Typography>
                                    <TextField
                                        className={classes.textField}
                                        InputProps={{
                                            endAdornment: (
                                                <>
                                                    <Typography
                                                        sx={{
                                                            color: "#A8A8A8",
                                                            cursor: "pointer",
                                                            marginRight: "8px",
                                                        }}
                                                    >
                                                        0/512
                                                    </Typography>
                                                </>
                                            ),
                                        }}
                                    />
                                </Grid>
                                <Grid size={{ md: 6 }}>
                                    <Typography className={classes.label}>Template Language <span style={{ color: '#FF0000' }}>*</span></Typography>
                                    <Autocomplete
                                        disablePortal
                                        options={language}
                                        className={classes.selectField}
                                        renderInput={(params) => <TextField {...params} placeholder='Select Language' />}
                                    />
                                </Grid>

                                <Grid container item size={{ md: 12 }} spacing={3}>
                                    <Grid size={{ md: 6 }}>
                                        <Typography className={classes.label}>Template Status <span style={{ color: '#FF0000' }}>*</span></Typography>
                                        <Autocomplete
                                            disablePortal
                                            options={statusList}
                                            className={classes.selectField}
                                            renderInput={(params) => <TextField {...params} placeholder='Select Template Status' />}
                                        />
                                    </Grid>
                                </Grid>

                                <Grid size={{ md: 12 }}>
                                    <Typography className={classes.label}>Template Category <span style={{ color: '#FF0000' }}>*</span></Typography>
                                    <Autocomplete
                                        disablePortal
                                        options={categoryList}
                                        className={classes.selectField}
                                        renderInput={(params) => <TextField {...params} placeholder='Select Template Category' />}
                                    />
                                </Grid>
                            </Grid>
                        </Card>

                        <Card sx={{ p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)', marginTop: '24px' }}>
                            <Grid container alignItems={'center'}>
                                <Grid size={{ md: 10 }}>
                                    <Typography sx={{ color: '#455967', fontWeight: '500', fontSize: '20px' }}>Template  Header</Typography>
                                    <Typography sx={{ color: '#A8A8A8', fontWeight: '400', fontSize: '16px' }}>Add a title or choose which type of media you will use for the header</Typography>
                                </Grid>
                                <Grid size={{ md: 2 }}>
                                    <Chip sx={{ width: '100%', fontSize: '14px', backgroundColor: '#445A68', color: '#FFF', height: '36px', borderRadius: '20px', padding: '4px' }} label="Choose Media" onClick={handleClick} deleteIcon={<ArrowDropDownIcon sx={{ fill: '#FFF' }} />} onDelete={() => { }} />

                                    <Menu
                                        anchorEl={anchorEl}
                                        open={Boolean(anchorEl)}
                                        onClose={handleClose}
                                    >
                                        <MenuItem onClick={() => handleClose('text')}>Text</MenuItem>
                                        <MenuItem onClick={() => handleClose('videos')}>Videos</MenuItem>
                                        <MenuItem onClick={() => handleClose('documents')}>Documents</MenuItem>
                                        <MenuItem onClick={() => handleClose('image')}>Image</MenuItem>
                                        <MenuItem onClick={() => handleClose('none')}>None</MenuItem>
                                    </Menu>
                                </Grid>

                                <Grid size={{ md: 12 }}>
                                    {
                                        selectedValue === 'image' ?
                                            <>
                                                <Divider sx={{ my: 2 }} />
                                                <img src={WhatsAppDemoImage}></img>
                                            </> : <></>
                                    }
                                </Grid>
                            </Grid>
                        </Card>

                        <Grid container my={3}>
                            <Grid size={{ md: 12 }}>
                                <Typography sx={{ color: '#455967', fontWeight: '500', fontSize: '20px' }}>Template Body</Typography>
                                <Typography sx={{ color: '#A8A8A8', fontWeight: '400', fontSize: '16px' }}>Add Text body for your Header</Typography>

                                <Card sx={{ p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)', marginTop: '24px' }}>
                                    <Grid container>
                                        <Grid size={{ md: 12 }}>
                                            <Typography className={classes.label}>Text Body <span style={{ color: '#FF0000' }}>*</span></Typography>
                                            <TextField
                                                className={classes.textField}
                                                InputProps={{
                                                    endAdornment: (
                                                        <>
                                                            <Typography
                                                                sx={{
                                                                    color: "#A8A8A8",
                                                                    cursor: "pointer",
                                                                    marginRight: "8px",
                                                                }}
                                                            >
                                                                0/1024
                                                            </Typography>
                                                        </>
                                                    ),
                                                }}
                                            />
                                        </Grid>

                                        <Grid size={{ md: 12 }} mt={1}>
                                            <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: '10px' }}>
                                                <Chip sx={{ fontSize: '16px', backgroundColor: '#445A68', color: '#FFF', height: '36px', borderRadius: '20px' }} label="Add Attachment" icon={<AddIcon style={{ fill: '#FFF' }} />} />
                                                <Box>
                                                    <IconButton><FormatBold /></IconButton>
                                                    <IconButton><FormatItalic /></IconButton>
                                                    <IconButton><FormatUnderlined /></IconButton>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    </Grid>
                                </Card>

                            </Grid>
                        </Grid>

                        <Grid container my={3}>
                            <Grid size={{ md: 12 }}>
                                <Typography sx={{ color: '#455967', fontWeight: '500', fontSize: '20px' }}>Template Footer</Typography>
                                <Typography sx={{ color: '#A8A8A8', fontWeight: '400', fontSize: '16px' }}>Add Text body for your Header</Typography>

                                <Card sx={{ p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)', marginTop: '24px' }}>
                                    <Grid container>
                                        <Grid size={{ md: 12 }}>
                                            <Typography className={classes.label}>Footer Text <span style={{ color: '#FF0000' }}>*</span></Typography>
                                            <TextField
                                                className={classes.textField}
                                                InputProps={{
                                                    endAdornment: (
                                                        <>
                                                            <Typography
                                                                sx={{
                                                                    color: "#A8A8A8",
                                                                    cursor: "pointer",
                                                                    marginRight: "8px",
                                                                }}
                                                            >
                                                                0/60
                                                            </Typography>
                                                        </>
                                                    ),
                                                }}
                                            />
                                        </Grid>
                                    </Grid>
                                </Card>

                            </Grid>
                        </Grid>

                        <Grid container my={3}>
                            <Grid size={{ md: 12 }}>
                                <Typography sx={{ color: '#455967', fontWeight: '500', fontSize: '20px' }}>Template Buttons</Typography>
                                <Typography sx={{ color: '#A8A8A8', fontWeight: '400', fontSize: '16px' }}>Choose Button Type that let your customer respond to your message or take action </Typography>

                                <Card sx={{ p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)', marginTop: '24px' }}>
                                    <Grid container>
                                        <Grid size={{ md: 12 }} display={'flex'} justifyContent={'space-between'} alignItems={'center'}>
                                            <Typography sx={{ color: '#A8A8A8', fontWeight: '400', fontSize: '16px' }}>Choose Button Type that let your customer respond to your message or take action </Typography>
                                            <Chip sx={{ fontSize: '16px', backgroundColor: '#445A68', color: '#FFF', height: '36px', borderRadius: '20px' }} label="Button Type" onClick={handleButtonTypeClick} deleteIcon={<ArrowDropDownIcon sx={{ fill: '#FFF' }} />} onDelete={() => { }} />

                                            <Menu
                                                anchorEl={anchorE2}
                                                open={Boolean(anchorE2)}
                                                onClose={handleButtonTypeClose}
                                            >
                                                <MenuItem onClick={() => handleButtonTypeClose('callToAction')}>Call To Action</MenuItem>
                                                <MenuItem onClick={() => handleButtonTypeClose('quickReply')}>Quick Reply</MenuItem>
                                                <MenuItem onClick={() => handleButtonTypeClose('none')}>None</MenuItem>
                                            </Menu>
                                        </Grid>

                                        {selectedValue2 === 'callToAction' ?
                                            <>
                                                <Grid container item size={{ md: 12 }} sx={{ mt: 2 }} spacing={2}>
                                                    <Grid size={{ xs: 12 }}><Divider /></Grid>
                                                    <Grid size={{ md: 3 }}>
                                                        <Typography className={classes.label}>Action Type</Typography>
                                                        <Autocomplete
                                                            disablePortal
                                                            options={actionTypeList}
                                                            className={classes.selectField}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </Grid>
                                                    <Grid size={{ md: 2.5 }}>
                                                        <Typography className={classes.label}>Button Text</Typography>
                                                        <TextField
                                                            className={classes.textField}
                                                        />
                                                    </Grid>
                                                    <Grid size={{ md: 2.5 }}>
                                                        <Typography className={classes.label}>URLType</Typography>
                                                        <Autocomplete
                                                            disablePortal
                                                            options={urlTypeList}
                                                            className={classes.selectField}
                                                            renderInput={(params) => <TextField {...params} />}
                                                        />
                                                    </Grid>
                                                    <Grid size={{ md: 3 }}>
                                                        <Typography className={classes.label}>Website URL</Typography>
                                                        <TextField
                                                            className={classes.textField}
                                                        />
                                                    </Grid>
                                                    <Grid size={{ md: 1 }} sx={{ display: 'flex', alignItems: 'end' }}>
                                                        <IconButton sx={{ marginBottom: '12px' }}>
                                                            <DeleteIcon sx={{ fill: "#FA5A5A" }} />
                                                        </IconButton>
                                                    </Grid>

                                                    <Grid size={{ md: 12 }} sx={{ display: 'flex', justifyContent: 'center' }}>
                                                        <Button
                                                            variant="outlined"
                                                            startIcon={<AddIcon />}
                                                            sx={{
                                                                borderRadius: "40px",
                                                                padding: "12px",
                                                                // backgroundColor: '#445A68',
                                                                color: '#445A68',
                                                                fontSize: "18px",
                                                                fontWeight: "600",
                                                                border: "1px solid rgba(68, 90, 104, 1)",
                                                            }}
                                                        >
                                                            Add Button
                                                        </Button>
                                                    </Grid>
                                                </Grid>
                                            </>
                                            : <></>}
                                    </Grid>
                                </Card>
                            </Grid>
                        </Grid>

                        <Grid container my={3}>
                            <Grid size={{ md: 12 }}>
                                <Typography sx={{ color: '#455967', fontWeight: '500', fontSize: '20px' }}>Add Sample Content</Typography>
                                <Typography sx={{ color: '#A8A8A8', fontWeight: '400', fontSize: '16px' }}>This helps to review and approval process. So that we can understand what message we are planning to send</Typography>
                            </Grid>

                            <Grid size={{ md: 6 }} marginTop={3}>
                                <Card sx={{ p: 3, borderRadius: '15px', boxShadow: '0px 4px 16.5px -6px rgba(0, 0, 0, 0.25)' }}>
                                    <Typography sx={{ color: '#A8A8A8', fontWeight: '500', fontSize: '18px' }}>Header Variable 1</Typography>
                                    <Box sx={{ display: 'flex', marginTop: '12px', alignItems: 'center', gap: '20px' }}>
                                        <input
                                            type="file"
                                            accept="image/*"
                                            onChange={handleImageChange}
                                            style={{ display: 'none' }}
                                            id="image-upload"
                                        />
                                        <label htmlFor="image-upload">
                                            <Chip
                                                sx={{ fontSize: '16px', backgroundColor: '#445A68', color: '#FFF', height: '36px', borderRadius: '20px', cursor: 'pointer' }}
                                                label="Upload"
                                            />
                                        </label>
                                        <Typography sx={{ color: '#A8A8A8' }}>
                                            {selectedImage ? "Image Selected" : "No File Chosen"}
                                        </Typography>
                                        {selectedImage && (
                                            <IconButton onClick={handleRemoveImage}>
                                                <DeleteIcon sx={{ color: "#FA5A5A" }} />
                                            </IconButton>
                                        )}
                                    </Box>
                                </Card>
                            </Grid>

                        </Grid>

                    </Grid>
                    <Grid size={{ md: 4 }} sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        {/* <img src={WhatsAppImage} width={'354px'} style={{ position: 'fixed' }}></img> */}

                        <ChatInterface />
                    </Grid>
                </Grid>
                <Box
                    sx={{
                        display: "flex",
                        justifyContent: "center",
                        gap: "10px",
                        paddingY: "10px",
                    }}
                >
                    <Button
                        variant="outlined"
                        startIcon={<ArrowBackIcon />}
                        sx={{
                            borderRadius: "40px",
                            padding: "15px 30px",
                            backgroundColor: '#445A68',
                            color: '#FFFFFF',
                            fontSize: "18px",
                            fontWeight: "600",
                            border: "1px solid rgba(68, 90, 104, 1)",
                        }}
                    >
                        Back
                    </Button>
                    <Button
                        variant="outlined"
                        endIcon={<ArrowForwardIcon />}
                        sx={{
                            borderRadius: "40px",
                            padding: "15px 30px",
                            color: "#FFFFFF",
                            background: 'linear-gradient(0deg, #13BECF 0%, #435D6B 100%)',
                            fontSize: "18px",
                            fontWeight: "600",
                            border: "1px solid #13BECF",
                        }}
                    >
                        Create Template
                    </Button>
                </Box>
            </form>
        </Box>
    )
}

export default CreateTemplateOld
