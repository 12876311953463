import { createAsyncThunk, createSlice } from "@reduxjs/toolkit"
import { authIpificationLoginThunk, fetchIpiFicationAuthPostThunk, fetchIpiFicationGetThunk, fetchIpiFicationPostThunk, fetchIpiFicationRespPostThunk, resendOtpThunk, VerifyOtpThunk } from "./idAuth.thunk"

export const fetchIpiFicationPostSlice = createAsyncThunk(
    "fetchIpiFicationPostThunk",
    fetchIpiFicationPostThunk
)

export const fetchIpiFicationGetSlice = createAsyncThunk(
    "fetchIpiFicationGetThunk",
    fetchIpiFicationGetThunk
)

export const fetchIpiFicationRespPostSlice = createAsyncThunk(
    "fetchIpiFicationRespPostThunk",
    fetchIpiFicationRespPostThunk
)

export const fetchIpiFicationAuthPostSlice = createAsyncThunk(
    "fetchIpiFicationAuthPostThunk",
    fetchIpiFicationAuthPostThunk
)

export const VerifyOtpSlice = createAsyncThunk(
    "VerifyOtpThunk",
    VerifyOtpThunk
)

export const resendOtpSlice = createAsyncThunk(
    "resendOtpThunk",
    resendOtpThunk
)

export const authIpificationLoginSlice = createAsyncThunk(
    "authIpificationLoginThunk",
    authIpificationLoginThunk
  );

const initialState = {
    ipificationAuthPostSlice: {},
    otp: '',
    resendOtp: '',
    demoPost: '',
    demoGet: '',
    demorespData: '',
    ipificationData:'',
    status: "idle",
    loading: false,
    error: null,
}


const idAuthSlice = createSlice({
    name: "idAuth",
    initialState,
    reducers: {},
    extraReducers: (builder) => {
        builder

            .addCase(fetchIpiFicationPostSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchIpiFicationPostSlice.fulfilled, (state, action) => {
                state.demoPost = action.payload;
                state.loading = false;
            })
            .addCase(fetchIpiFicationPostSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchIpiFicationGetSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchIpiFicationGetSlice.fulfilled, (state, action) => {
                state.demoGet = action.payload;
                state.loading = false;
            })
            .addCase(fetchIpiFicationGetSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            .addCase(fetchIpiFicationRespPostSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchIpiFicationRespPostSlice.fulfilled, (state, action) => {
                state.demorespData = action.payload;
                state.loading = false;
            })
            .addCase(fetchIpiFicationRespPostSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            // Auth

            .addCase(fetchIpiFicationAuthPostSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(fetchIpiFicationAuthPostSlice.fulfilled, (state, action) => {
                state.ipificationAuthPostSlice = action.payload;
                state.loading = false;
            })
            .addCase(fetchIpiFicationAuthPostSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            // Verify Otp

            .addCase(VerifyOtpSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(VerifyOtpSlice.fulfilled, (state, action) => {
                state.otp = action.payload;
                state.loading = false;
            })
            .addCase(VerifyOtpSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            // Resend Verify Otp

            .addCase(resendOtpSlice.pending, (state) => {
                state.loading = true;
                state.error = null;
            })
            .addCase(resendOtpSlice.fulfilled, (state, action) => {
                state.resendOtp = action.payload;
                state.loading = false;
            })
            .addCase(resendOtpSlice.rejected, (state, action) => {
                state.loading = false;
                state.error = action.error.message;
            })

            // Login Auth

            .addCase(authIpificationLoginSlice.pending, (state) => {
                state.status = 'loading';
              })
              .addCase(authIpificationLoginSlice.fulfilled, (state, action) => {
                state.status = 'succeeded';
                state.ipificationData = action.payload;
              })
              .addCase(authIpificationLoginSlice.rejected, (state, action) => {
                state.error = action.error.message;
                state.loading = false;
              })

    }
})


export default idAuthSlice.reducer;
