import React from 'react'
import CustomModal from '../CustomModal/CustomModal'
import TemplateView from '../../pages/NewCampaign/Components/TemplateView'

const CampaignTemplateModal = ({open, handleClose}) => {
  return (
    <CustomModal>
        <TemplateView open={open} handleClose={handleClose}/>
    </CustomModal>
  )
}

export default CampaignTemplateModal